import TagPageTemplate from '@/templates/tag';
import { ArticleTag, GatsbyPageProps } from '@/types';

export default function BitcoinPage(props: GatsbyPageProps) {
  return (
    <TagPageTemplate
      location={props.location}
      title="Crypto MACD Chart"
      tag={ArticleTag.MACD}
    />
  );
}
